import Main from './Main'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { saveAppointment, checkSlotAvailabilty, addDays, getSlotsForADate, updateStoreSlots, getAppointmentDetails, cancelAppointment, updateStoreSlotsOnCancellation, getStoresBasedOnGeoLocation } from './actions/Common'

function mapStateToProps(state) {
  return {
    common: state.common
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveAppointment, checkSlotAvailabilty, addDays, getSlotsForADate, updateStoreSlots, getAppointmentDetails, cancelAppointment, updateStoreSlotsOnCancellation, getStoresBasedOnGeoLocation
  }, dispatch)
}

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))

export default App