import React, {Component} from 'react'
import { Route} from 'react-router-dom';
import Appointment from './components/Appointment'

class Main extends Component
{
    render()
    { 
        return<div>
            <Route exact path='/' render={({ history }) => (<div><Appointment {...this.props} onHistory={history} /></div>)} />
            <Route exact path='/scbm' render={() => (<div><Appointment {...this.props}  /></div>)} />
        </div>
    }
}

export default Main;