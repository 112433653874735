import React, { Component } from 'react';

class Header extends Component {

    // constructor() {
    //     super()
    //     this.state = {
    //         menuSelected: '',
    //         showChangePasswordModal: false
    //     }
    //     this.onMenuClicked = this.onMenuClicked.bind(this)
    // }

    // onMenuClicked(menu) {
    //     if (this.state.menuSelected === menu) {
    //         this.setState({ menuSelected: '' })
    //     }
    //     else {
    //         this.setState({ menuSelected: menu })
    //     }
    // }

    // render() {
    //     return (
    //         <div>
    //             <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-white" style={{ textAlign: "center" }}>

    //                 <aside className="notificationBar--shop fixed-top">
    //                 </aside>

    //                 <a className="navbar-brand mr-auto mr-lg-0" href="https://starbucks.in" style={{ padding: "0px 20px 0px 0px", marginTop: "5px", width: "100%" }}>
    //                     <img src={require('../StarbucksLogo.png')} alt="Starbucks Logo" height="62" />
    //                 </a>

    //             </nav>
    //         </div>
    //     );
    // };


    render() {
        return (
            <div className="header">
                    <a className="logo" href="https://starbucks.in">
                        <img src={require('../StarbucksLogo.png')} alt="Starbucks Logo" />
                    </a>
            </div>
        );
    };

}

export default Header