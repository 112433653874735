
import * as firebase from 'firebase';

var config = {
  apiKey: "AIzaSyDU8LquL8LEHACl6zOZPCl6qhvRgKpZ0-M",
  authDomain: "posible-app-prod.firebaseapp.com",
  databaseURL: "https://posible-app-prod.firebaseio.com",
  projectId: "posible-app-prod",
  storageBucket: "posible.in",
  // messagingSenderId: "1057547901573",
  appId: '1:1057547901573:android:567360a4165b2f2a',
};

firebase.initializeApp(config);
export const database = firebase.firestore()
export const functions = firebase.functions()
export const storageRef = firebase.storage().ref();
export const auth = firebase.auth();

export default firebase




