import * as constants from '../Constants'

const intialState= {
    auth:null,
    user:null,
}

export default function(state=intialState, action) {
    switch (action.type) {
        case constants.SIGN_OUT:
            return intialState
        case constants.SET_AUTH:
            return {...state, auth:action.auth } 
        case constants.SET_USER:
            return {...state, user:action.user}
        default:
            return state;
    }
}
