import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const ViewMessage = ({ msgCode, storeName, reBook }) => {
  
  return (
    <div className="thank-you" style={{letterSpacing: ".1rem"}}>
        {msgCode === 1 && <div><h1>Success!</h1> <br/>
        <h3 style={{ fontSize: "14px"}}>Thank you for booking your slot for the dine-in experience at {storeName}. <br /><br />
          We request you to please maintain all the Starbucks safety precautions during your visit. <br /><br /> We look forward to serving you.
        </h3>
        </div>}
        { msgCode ===2 && <div><h1>Cancellation!</h1> <br/>
          <h3 style={{ fontSize: "14px"}}>Your booking to visit our Starbucks store has been cancelled.
          <br/>
       
            </h3>
        <div className="col-md-12" style={{ textAlign: "center" }}>
          <span style={{ fontWeight: "1.1rem" }}>To visit our Starbucks store </span> <Link onClick={()=> reBook()}>Re book</Link>
        </div>
          </div>
        }
      {msgCode === 3 && <div><h1>Not Available!</h1> <br />
        <h3 style={{ fontSize: "14px" }}>No nearby store available.
          <br />
        </h3>
      </div>
      }
        
    </div>
  );
};

ViewMessage.propTypes = {
  msgCode: PropTypes.number.isRequired,
};

export default ViewMessage;