import firebase, { database } from '../firebase'

export function saveAppointment(appointment) {
  return () => {
    appointment.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    appointment.IsCancelled = false;
    appointment.Action = "NEW";
    return database.collection("BookAppointment").add({
      ...appointment
    })
      .then(function (docRef) {
        return docRef.id
      })
      .catch(function (error) {
        console.error("Error adding Appointment: ", error);
      });
  };
};

export function checkSlotAvailabilty(slotDate, slot, storeID) {
  return () => {
    return database.collection("StoreAppointmentSlots").where("SlotDate", "==", slotDate).where("Slot", "==", slot).where("StoreID", "==", storeID).get().then((querySnapshot) => {
      let available = true
      querySnapshot.forEach((doc) => {
        if (Number(doc.data().Available) === 0) {
          available = false
        }
      });
      return available;
    });
  };
};

export function addDays(days) {
  return (dispatch) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
};

export function getSlotsForADate(slotDate, storeID) {
  return () => {
    // console.log("date", slotDate);
    // console.log("store", storeID);

    return database.collection("StoreAppointmentSlots").where("SlotDate", "==", slotDate).where("StoreID", "==", storeID).get().then((querySnapshot) => {
      let slots = []
      querySnapshot.forEach((doc) => {
        let slot = {
          value: doc.data().Slot,
          text: doc.data().Slot,
          available: Number(doc.data().Available),
          booked: Number(doc.data().Booked),
        }
        slots.push(slot);
      });
      return slots;
    });
  };
};

export function updateStoreSlots(slotDate, slot, storeID) {
  return () => {
    return database.collection("StoreAppointmentSlots").where("SlotDate", "==", slotDate).where("Slot", "==", slot).where("StoreID", "==", storeID).get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          let available = Number(Number(doc.data().Available) - 1);
          let booked = Number(Number(doc.data().Booked) + 1);
          await database.collection("StoreAppointmentSlots").doc(doc.id).update({ Available: available, Booked: booked });
        };
      });
      return "success";
    });
  };
};

export function getAppointmentDetails(key) {
  return (dispatch) => {
    return database.collection("BookAppointment").doc(key).get().then((doc) => {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log("No such Appointment!");
      }
    })
  }
};

export function cancelAppointment(key) {
  return (dispatch, getState) => {
    return database.collection("BookAppointment").doc(key).update({
      IsCancelled: true
      , ActionOn: firebase.firestore.FieldValue.serverTimestamp()
      , Action: 'CANCEL'
    }).then(() => {
      return key
    }).catch(function (error) {
      console.error("Error cancelling appointment: ", error);
    })
  }
};

export function updateStoreSlotsOnCancellation(slotDate, slot, storeID) {
  return () => {
    return database.collection("StoreAppointmentSlots").where("SlotDate", "==", slotDate).where("Slot", "==", slot).where("StoreID", "==", storeID).get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          let available = Number(Number(doc.data().Available) + 1);
          let booked = Number(Number(doc.data().Booked) - 1);
          await database.collection("StoreAppointmentSlots").doc(doc.id).update({ Available: available, Booked: booked });
        };
      });
      return "success";
    });
  };
};

export function getStoresBasedOnGeoLocation(registrationID, latitude, longitude, event = false) {
  let searchParameters = {
    "from": 0,
    "size": 500,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID.keyword": registrationID } }
        ],
        "must_not": [
          { "match": { "IsDeleted": "true" } }
        ]
      }
    },
    "sort": [
      {
        "StoreCode.keyword": {
          "order": "asc"
        }
      }
    ]
  }

  if (event) {
    searchParameters.query.bool.must.push({
      "match_phrase": {
        "CustomField1": "Starbucks Cold Brew Masterclass at"
      }
    })
  }
  else {
    searchParameters.query.bool.must.push({
      "geo_distance": {
        "distance": "20km",
        "Location": {
          "lat": latitude,
          "lon": longitude
        }
      }
    })
  }

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getStores');
    return addMessage({ text: searchParameters }).then(function (result) {
      const stores = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let address = data._source.Address1 + ' ' + data._source.Address2 + ' ' + data._source.City + '-' + data._source.PostalCode
        let store = {
          key: data._id,
          name: data._source.StoreName,
          address: address,
          customField1: data._source.CustomField1
        }
        stores.push(store);
        return true;
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: stores }
    });
  }
}