import React, { Component } from 'react'
import Header from './Header'
import '../css/App.css';
import ViewMessage from './ViewMessage'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css';
import moment from 'moment';

class Appointment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            emailID: '',
            contactNo: '',
            slotDate: '',
            slot: '',
            nameError: '',
            emailIDError: '',
            contactNoError: '',
            slotDateError: '',
            slotError: '',
            key: '',
            slotMinDate: '',
            slotMaxDate:'',
            slots: [
                { value: "9:00 AM", text: "9:00 AM", available: 0, booked: 0 },
                { value: "10:00 AM", text: "10:00 AM", available: 0, booked: 0 },
                { value: "11:00 AM", text: "11:00 AM", available: 0, booked: 0 },
                { value: "12:00 PM", text: "12:00 PM", available: 0, booked: 0 },
                { value: "1:00 PM", text: "1:00 PM", available: 0, booked: 0 },
                { value: "2:00 PM", text: "2:00 PM", available: 0, booked: 0 },
                { value: "3:00 PM", text: "3:00 PM", available: 0, booked: 0 },
                { value: "4:00 PM", text: "4:00 PM", available: 0, booked: 0 },
                { value: "5:00 PM", text: "5:00 PM", available: 0, booked: 0 },
                { value: "6:00 PM", text: "6:00 PM", available: 0, booked: 0 },
                { value: "7:00 PM", text: "7:00 PM", available: 0, booked: 0 },
                { value: "8:00 PM", text: "8:00 PM", available: 0, booked: 0 },
            ],
            saving: false,
            success: false,
            showError: false,
            loadingSlots: false,
            cancelled: false,
            store: null,
            stores: [],
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.LoadData = this.LoadData.bind(this);
        this.LoadSlots = this.LoadSlots.bind(this);
        this.getLocationsNearestToTheUser = this.getLocationsNearestToTheUser.bind(this);
        this.geoLocationFound = this.geoLocationFound.bind(this);
        this.onRowClick = this.onRowClick.bind(this)
    };

    async componentDidMount() {

        if (window.localStorage.getItem("appointment-key")) {
            let key = window.localStorage.getItem("appointment-key");
            if (key && key !== '') {
                this.setState({key: key});
                // console.log(key);
                let appointment = await this.props.getAppointmentDetails(key);
                this.setState({
                    name: appointment.Name,
                    emailID: appointment.EmailID,
                    contactNo: appointment.ContactNo,
                    slotDate: appointment.SlotDate,
                    slot: appointment.Slot,
                    store: {name: appointment.Store, key: appointment.StoreID }
                });
                // $("input").on("change", function () {
                //     this.setAttribute(
                //         "data-date",
                //         moment(appointment.SlotDate, "YYYY-MM-DD")
                //             .format(this.getAttribute("data-date-format"))
                //     )
                // }).trigger("change")
            }
        }
        else {
            await this.getLocationsNearestToTheUser();
        }
    };

    async getLocationsNearestToTheUser() {
        if ("geolocation" in navigator) {
            // console.log("Available");
            navigator.geolocation.getCurrentPosition(this.geoLocationFound);
        } else {
            console.log("Not Available");
            this.setState({ geoLocationFound: false })
            //show error locaiton services is not enabld
        }
    };

    async geoLocationFound(position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        let event = this.props.location.pathname.includes('/scbm'); //this.props.match.params.key; 
        let result = null
        let stores = []
        if (event) {
            result = await this.props.getStoresBasedOnGeoLocation("WbqD628tRlXjVF9nar3I", latitude, longitude, true);
            stores = result.searchResult;
        }
        else {
            result = await this.props.getStoresBasedOnGeoLocation("WbqD628tRlXjVF9nar3I", latitude, longitude);
            stores = result.searchResult;
        }
        if(stores.length > 1)
        {
            this.setState({ stores: stores });
        }
        else if(stores.length === 1)
        {
            this.setState({ stores: stores, store: stores[0] });
        }
    };

    onRowClick(store) {
        this.setState({ store: store });
        this.LoadData(store);
    };

    async LoadData(store) {
        let stores = [{ ...store }];
        this.setState({ stores: stores })
        this.setState({ cancelled: false, key: '' });

        var date = this.props.addDays(1);
        var date1 = this.props.addDays(7);

        date = date.toISOString().split('T')[0];
        date1 = date1.toISOString().split('T')[0];

        await this.LoadSlots(date, store);

        this.setState({ slotDate: date, slotMinDate: date, slotMaxDate: date1 });
    };

    async LoadSlots(date, store)
    {
        this.setState({ loadingSlots: false });
        let appointments = await this.props.getSlotsForADate(date, store.key);
        let slots = [
            { value: "9:00 AM", text: "9:00 AM", available: 0, booked: 0 },
            { value: "10:00 AM", text: "10:00 AM", available: 0, booked: 0 },
            { value: "11:00 AM", text: "11:00 AM", available: 0, booked: 0 },
            { value: "12:00 PM", text: "12:00 PM", available: 0, booked: 0 },
            { value: "1:00 PM", text: "1:00 PM", available: 0, booked: 0 },
            { value: "2:00 PM", text: "2:00 PM", available: 0, booked: 0 },
            { value: "3:00 PM", text: "3:00 PM", available: 0, booked: 0 },
            { value: "4:00 PM", text: "4:00 PM", available: 0, booked: 0 },
            { value: "5:00 PM", text: "5:00 PM", available: 0, booked: 0 },
            { value: "6:00 PM", text: "6:00 PM", available: 0, booked: 0 },
            { value: "7:00 PM", text: "7:00 PM", available: 0, booked: 0 },
            { value: "8:00 PM", text: "8:00 PM", available: 0, booked: 0 },
        ];

        if (appointments && appointments.length > 0) {
            for (let index = 0; index < appointments.length; index++) {
                const appointment = appointments[index];
                slots.filter((f) => {
                    if (f.value === appointment.value) {
                        f.available = Number(appointment.available);
                        f.booked = Number(appointment.booked);
                    }
                    return null;
                })
            }
        }
        this.setState({ slots: slots, loadingSlots: true });
    }

    async onSave(slot, index) {

        this.setState({ saving: true });

        let nameError = '',
            emailIDError = '',
            contactNoError = '',
            slotDateError = '',
            slotError = ''

        if (this.state.name.trim() === '') {
            nameError = 'Please enter Name'
        };

        if(this.state.name.trim() !== '')
        {
            var pattern2 = new RegExp("[aA-zZ ]{2,30}$");
            if (!pattern2.test(this.state.name.trim())) {
                nameError = "Please enter valid Name";
            }
        };

        if (this.state.emailID.trim() === '') {
            emailIDError = 'Please enter Email ID'
        };

        if (this.state.emailID.trim() !== '') {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.emailID.trim())) {
                emailIDError = "Please enter valid Email ID";
            }
        };

        if (this.state.contactNo.trim() === '') {
            contactNoError = 'Please enter Mobile Number'
        };

        if (this.state.contactNo.trim() !== '') {
            var pattern1 = new RegExp(/^[0-9\b]+$/);
            if (!pattern1.test(this.state.contactNo.trim())) {
                contactNoError = "Please enter only number.";
            } else if (this.state.contactNo.trim().length !== 10) {
                contactNoError = "Please enter valid Mobile Number";
            }
        };

        if (this.state.slotDate.trim() === '') {
            slotDateError = 'Please select Slot Date'
        };

        if (this.state.slotDate !== '') {
            if (new Date(this.state.slotDate) <= new Date()) {
                slotDateError = 'Please select Slot Date greater than current date'
            }
        };

        if (slot === '') {
            slotError = 'Please select Slot'
        };

        if (nameError !== '' || emailIDError !== '' || contactNoError !== '' || slotDateError !== '' || slotError !== '') {
            this.setState({ nameError: nameError, emailIDError: emailIDError, contactNoError: contactNoError, slotDateError: slotDateError, slotError: slotError, saving: false });
            return;
        };

        let available = await this.props.checkSlotAvailabilty(this.state.slotDate, slot, this.state.store.key);

        if (!available) {
            slotError = 'The slot is already full. Please select a different slot'
            this.setState({ showError: true, slotError: slotError, saving: false })
            return
        };

        let appointment = {
            Name: this.state.name,
            ContactNo: this.state.contactNo,
            EmailID: this.state.emailID,
            SlotDate: this.state.slotDate,
            Slot: slot,
            Sequence: index,
            Store: this.state.store.name,
            StoreID: this.state.store.key,
            RegistrationID: "WbqD628tRlXjVF9nar3I"//this.state.store.RegistrationID
        };
        // console.log('appointment',appointment);
        // console.log('this.state.store',this.state.store);
        // return ;
        let key = await this.props.saveAppointment(appointment);
        if (key !== "") {
            await this.props.updateStoreSlots(appointment.SlotDate, appointment.Slot, this.state.store.key);
            this.setState({ success: true, saving: false });
            window.localStorage.setItem("appointment-key", key);
        }
    };

    async onCancel(key) {
        if(key && key !== '')
        {
            await this.props.cancelAppointment(key);
            await this.props.updateStoreSlotsOnCancellation(this.state.slotDate, this.state.slot, this.state.store.key);
            window.localStorage.removeItem("appointment-key");
            this.setState({
                cancelled: true,
            });
        }
    };

    async onDateChange(evt) {

        // $("input").on("change", function() {
        //     this.setAttribute(
        //         "data-date",
        //         moment(this.value, "YYYY-MM-DD")
        //             .format(this.getAttribute("data-date-format"))
        //     )
        // }).trigger("change")

        let slotDate = evt.target.value;
        if (slotDate !== "") {
            
            if(new Date(slotDate) < new Date(this.state.slotMinDate) || new Date(slotDate) > new Date(this.state.slotMaxDate))
            {
                this.setState({ slotDate: slotDate, slotDateError: 'Please select a date between ' + moment(new Date(this.state.slotMinDate)).format("DD-MMM-yyyy") + " and " + moment(new Date(this.state.slotMaxDate)).format("DD-MMM-yyyy") })
                return;
            }
            await this.LoadSlots(slotDate, this.state.store);
            this.setState({  slotDate: slotDate, slotDateError: '' });
        }
    };

    render() {
        return <React.Fragment>
            
            <Header {...this.props} />
            {(!this.state.success && !this.state.cancelled && this.state.store) && <main className="container">
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-3 p-3 bg-white rounded shadow-sm" style={{ minHeight: "180px", fontSize: "14px" }}>
                            <h3 className="pb-2 mb-0" style={{ fontWeight: "500", fontFamily: "Lato", fontSize: "24px", letterSpacing: ".1rem", margin:"10px 15px 15px 15px" }}>
                                <span >
                                </span> 
                                {this.state.store.customField1 === '' && <React.Fragment>{this.state.key === '' ? "Pre-registration": "Your Pre-registration "} for dine-in at {this.state.store ? this.state.store.name: ''} </React.Fragment> }
                                {this.state.store && this.state.store.customField1 !== '' && <React.Fragment>{this.state.store.customField1 + " " + this.state.store.name} </React.Fragment> }
                            </h3>
                            {this.state.key === '' && <h4 className="pb-2 mb-0" style={{ fontSize: "0.9rem", fontFamily: "Lato", marginTop: "10px", letterSpacing: ".1rem", padding: "0px 15px 0px 15px" }}>Kindly complete this form to pre-register for a time slot</h4>}
                            <br />
                            <div className="mb-3 col-md-6">
                                <div className={this.state.nameError !== '' ? "form-group has-error" : "form-group "} style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>
                                    <label>Name</label>
                                    <input type="text" className="form-control" style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}
                                        disabled={(this.state.key && this.state.key !== '')}
                                        value={this.state.name}
                                        onChange={(evt) => this.setState({ name: evt.target.value, nameError: '' })} />
                                    {this.state.nameError !== '' && <span className="help-block">{this.state.nameError}</span>}
                                </div>
                            </div>
                            <div className="mb-3 col-md-6">
                                <div className={this.state.contactNoError !== '' ? "form-group has-error" : "form-group "} style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>
                                    <label >Mobile Number (10 digits)</label>
                                    <div className="input-group">
                                        {/* <span className="input-group-addon">+91</span> */}
                                        <input type="number" maxLength="10" className="form-control" style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}
                                        disabled={(this.state.key && this.state.key !== '')}
                                        value={this.state.contactNo}
                                            onChange={(evt) => this.setState({ contactNo: evt.target.value, contactNoError: '' })} />
                                    </div>
                                    {this.state.contactNoError !== '' && <span className="help-block">{this.state.contactNoError}</span>}
                                </div>
                            </div>
                            <div className="mb-3 col-md-6">
                                <div className={this.state.emailIDError !== '' ? "form-group has-error" : "form-group "} style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>
                                    <label >Email ID</label>
                                    <input type="email" className="form-control" style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}
                                        disabled={(this.state.key && this.state.key !== '')}
                                        value={this.state.emailID}
                                        onChange={(evt) => this.setState({ emailID: evt.target.value, emailIDError: '' })} />
                                    {this.state.emailIDError !== '' && <span className="help-block">{this.state.emailIDError}</span>}
                                </div>
                            </div>
                            <div className="mb-3 col-md-6">
                                <div className={this.state.slotDateError !== '' ? "form-group has-error" : "form-group "} style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>
                                    <label >Select a date in the following week</label>
                                    <input type="date" data-date="" data-date-format="DD-MMM-YYYY" className="form-control"
                                        value={this.state.slotDate}
                                        style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}
                                        disabled={(this.state.key && this.state.key !== '')}
                                        onChange={(evt) => this.onDateChange(evt)}
                                    />
                                    {this.state.slotDateError !== '' && <span className="help-block">{this.state.slotDateError}</span>}
                                </div>
                            </div>
                            {this.state.key !== '' && <div className="mb-3 col-md-6">
                                <div className={this.state.slotDateError !== '' ? "form-group has-error" : "form-group "} style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>
                                    <label >Slot Time</label>
                                    <input type="text" className="form-control" style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}
                                        disabled={(this.state.key && this.state.key !== '')}
                                        value={this.state.slot}
                                    />
                                    {this.state.slotDateError !== '' && <span className="help-block">{this.state.slotDateError}</span>}
                                </div>
                            </div>}
                            {this.state.loadingSlots && <div className="mb-3 col-md-6">
                                <div className="list" style={{ paddingTop: "0px" }}>
                                    <React.Fragment>
                                        {this.state.slots.map((slot, index) =>
                                            <div className="list-row seperator" key={index}>
                                                <div style={{ width: "60%" }} >
                                                    <React.Fragment>
                                                        {slot.available === 0 && <React.Fragment>
                                                            <div style={{ color: "red", fontFamily: "Lato", letterSpacing: ".1rem" }}>{slot.text}</div>
                                                            <div style={{ color: "red", fontFamily: "Lato", letterSpacing: ".1rem" }}>All Slots Booked</div>
                                                        </React.Fragment>}
                                                        {slot.available > 0 && <React.Fragment>
                                                            <div style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}>{slot.text}</div>
                                                            <div style={{ fontFamily: "Lato", letterSpacing: ".1rem" }}> {slot.available} Slots Available</div>
                                                        </React.Fragment>}
                                                    </React.Fragment>
                                                </div>
                                                <div style={{ width: "40%" }} >
                                                    <div className="btn-group pull-right">
                                                        <div>
                                                            {(slot.available > 0 && this.state.key === '') && <React.Fragment>
                                                                <button type="button"
                                                                disabled={this.state.saving}
                                                                    onClick={() => this.onSave(slot.value, index)}
                                                                    className="btn btn-md btn-flat btn-default " style={{ backgroundColor: "rgba(0,99,65,1)", borderColor: "rgba(0,99,65,1)", color: "white", fontFamily: "Lato", fontWeight: "400", letterSpacing: ".1rem" }} >Book</button>
                                                &nbsp;&nbsp;</React.Fragment>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </React.Fragment>
                                </div>
                            </div>}
                            {this.state.key !== '' && <div className="col-md-6" style={{textAlign:"center"}}>
                                <button className="btn btn-default" onClick={() => this.onCancel(this.state.key)} style={{ backgroundColor: "red", borderColor: "red", color: "white", fontFamily: "Lato", fontWeight: "400", letterSpacing: ".1rem" }} >Cancel</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </main>}
            {(this.state.stores && this.state.stores.length > 1) && <main className="container">
                <br />
                <div className="row"> 
                <div className="mb-3 col-md-6">
                    <div className="list" style={{ paddingTop: "0px" }}>
                        <React.Fragment>
                            {this.state.stores.map((store, index) =>
                                <div className="list-row seperator" key={index} onClick={() => this.onRowClick(store)}>
                                    <div style={{ width: "60%" }} >
                                        <React.Fragment>
                                            <React.Fragment>
                                                <div style={{ fontFamily: "Lato", letterSpacing: ".1rem", fontWeight:"bold" }}>{store.name}</div>
                                                <div style={{ fontFamily: "Lato", letterSpacing: ".1rem", fontSize: "small" }}> <span><i className="fa fa-map-marker" aria-hidden="true"></i></span> {store.address}</div>
                                            </React.Fragment>
                                        </React.Fragment>
                                    </div>
                                </div>)}
                        </React.Fragment>
                    </div>
                </div>
            </div> 
            </main>}
            {(this.state.success || this.state.cancelled) && <ViewMessage msgCode={this.state.success ? 1 : this.state.cancelled ? 2 : 0} storeName={this.state.store ? this.state.store.name : ''} reBook={() => this.LoadData(this.state.store)}></ViewMessage>}
            {(!this.state.geoLocationFound && this.state.key === '' && this.state.stores.length === 0) && <ViewMessage msgCode={3}></ViewMessage>}
            <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center>
                <div style={{ width: "250px" }}>
                    <h3 style={{ marginTop: "0px", fontFamily: "Lato", marginBottom: "10px",letterSpacing: ".1rem"  }}>Error Message</h3>
                    <div className="box-body" style={{ fontFamily: "Lato", margin: "2px", letterSpacing: ".1rem"  }}>
                        {this.state.slotError !== "" && this.state.slotError}
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-danger pull-right" style={{ fontFamily: "Lato", letterSpacing: ".1rem"  }}
                            onClick={() => this.setState({ showError: false })}>OK</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    };
};

export default Appointment